import Vue from 'vue'
import { BootstrapVue,  } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/mixins'

import '../modules'


import moment from "moment"
moment.locale("da")

import numeral from "numeral"
import "numeral/locales/da-dk"
numeral.locale("da-dk")

// BSV Plugin Registration
Vue.use(BootstrapVue, {
    BFormDatepicker: {
        locale: "da",
        placeholder: "Vælg dato",
        startWeekday: "1",
        hideHeader: true
    }
})

// Composition API
Vue.use(VueCompositionAPI)

import * as GoogleMaps from './components/gmap'
Vue.use(GoogleMaps, { load: { key: "AIzaSyDF6YCqLe0ngwW72OMulcPbTBgcX6PNdqg", libraries: [] } })


// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$moment = moment
Vue.prototype.$numeral = numeral

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
