<template>
    <div>

        <div class="app-calendar overflow-hidden border">
            <div class="row no-gutters" v-if="$can('read', 'Client', 'calendar')">

                <!-- Calendar -->
                <div class="col position-relative">
                    <div class="card shadow-none border-0 mb-0 rounded-0">
                        <div class="card-body pb-0">
                            <full-calendar ref="refCalendar"
                                           :options="calendarOptions"
                                           class="full-calendar">
                                <template v-slot:eventContent="arg">
                                    <span class="fc-event-title d-block">{{ arg.event.title }}</span>
                                </template>
                            </full-calendar>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 bg-white pa-10">

                    <div class="calendar-items" v-for="item in orderItems" :key="item.id">
                        <div class="card-body">
                            <h4 class="card-title">
                                {{$moment(item.start).format("HH:mm")}} - {{item.name}}
                                <span class="order-number">#{{item.orderNumber}}</span>
                            </h4>
                            <h6 class="card-subtitle">
                                Pris: {{item.price}}

                                <span>{{$store.state.shippingMethod.find(x => x.value == item.shippingMethod).text}}</span>
                            </h6>

                            <b-link :to="item.url" class="stretched-link"></b-link>
                        </div>
                    </div>

                </div>


            </div>
        </div>





        <!--<b-row class="match-height">
        <b-col lg="2" cols="6">
            <card-statistic-order-chart />
        </b-col>
        <b-col lg="2" cols="6">
            <card-statistic-profit-chart />
        </b-col>
        <b-col lg="8" cols="12">
            <card-statistics-group />
        </b-col>
    </b-row>-->
        <!--<b-row class="match-height">
        <b-col xl="2" md="4" sm="6">
            <statistic-card-vertical icon="EyeIcon"
                                     statistic="36.9k"
                                     statistic-title="Views"
                                     color="info" />
        </b-col>
        <b-col xl="2" md="4" sm="6">
            <statistic-card-vertical color="warning"
                                     icon="MessageSquareIcon"
                                     statistic="12k"
                                     statistic-title="Comments" />
        </b-col>
        <b-col xl="2" md="4" sm="6">
            <statistic-card-vertical color="danger"
                                     icon="ShoppingBagIcon"
                                     statistic="97.8k"
                                     statistic-title="Orders" />
        </b-col>
        <b-col xl="2" md="4" sm="6">
            <statistic-card-vertical color="primary"
                                     icon="HeartIcon"
                                     statistic="26.8"
                                     statistic-title="Favorited" />
        </b-col>
        <b-col xl="2" md="4" sm="6">
            <statistic-card-vertical color="success"
                                     icon="AwardIcon"
                                     statistic="689"
                                     statistic-title="Reviews" />
        </b-col>
        <b-col xl="2" md="4" sm="6">
            <statistic-card-vertical hide-chart
                                     color="danger"
                                     icon="TruckIcon"
                                     statistic="2.1k"
                                     statistic-title="Returns" />
        </b-col>
    </b-row>-->


    </div>

</template>


<script>
    import FullCalendar from '@fullcalendar/vue'

    import dayGridPlugin from "@fullcalendar/daygrid";
    import timeGridPlugin from "@fullcalendar/timegrid";
    import listPlugin from "@fullcalendar/list";
    import interactionPlugin from "@fullcalendar/interaction";
    import daLocale from '@fullcalendar/core/locales/da';


    import CardStatisticOrderChart from './demo/CardStatisticOrderChart.vue'
    import CardStatisticProfitChart from './demo/CardStatisticProfitChart.vue'
    import CardStatisticsGroup from './demo/CardStatisticsGroup.vue'

    import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
    import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
    import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
    import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'

    export default {
        components: { FullCalendar },
        data: self => ({
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin, listPlugin],
                locale: daLocale,
                initialView: "dayGridMonth",
                headerToolbar: {
                    start: "prev,next, title",
                    end: "dayGridMonth,listMonth",
                },
                events: (info, successCallback) => {
                    // If there's no info => Don't make useless API call
                    if (!info) return;

                    //self.$http.post("/api/dashboard/calendar", { start: info.start, end: info.end }).then(x => {
                    //    successCallback(x.data)
                    //}).catch(() => {

                    //})
                },
                eventClassNames(obj) {
                    var colorName = "success" //calendarsColor[obj.event.extendedProps.shippingMethod];
                    return [
                        // Background Color
                        `bg-light-${colorName}`,
                    ];
                },
                eventClick({ event: clickedEvent }) {
                    self.getItems(clickedEvent.startStr)
                },
                editable: false,
                eventResizableFromStart: false,
                dragScroll: true,
                dayMaxEvents: 1,
                navLinks: false,
                rerenderDelay: 0,
                contentHeight: 500,
                eventTimeFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false
                }
            },
            orderItems: []
        }),
        computed: {

        },
        created() { },
        mounted() { },
        methods: {
            getItems(start) {
                //this.$http.post("/api/dashboard/calendar/items", { start: start }).then(x => {
                //    this.orderItems = x.data
                //}).catch(() => {

                //})
            }
        },
    }
</script>

<style lang="scss">
    @import "@core/scss/vue/apps/calendar.scss";

    .fc-event {

        > span {
            display: block;
            width: 100%;
        }

        .fc-event-title {

        }

        .fc-event-time {
            font-weight: normal;
        }

        .fc-event-time > span {
            float: right;
            display: block;
        }
    }

    .calendar-items {
        position: relative;
        margin-bottom: 0px;
        border: 1px solid #ddd;

        .card-body {
            padding: 0px;
        }

        .card-title {
            background: #f8f8f8;
            padding: 8px 14px;
            font-size: 1em;
            font-weight: bold;
            margin: 0;

            span {
                float: right;
            }
        }

        .card-subtitle {
            padding: 8px 14px;
            color: #6e6b7b;
            margin: 0;

            span {
                float: right;
                font-size: 0.9em;
            }
        }
    }
</style>