const lazy = fn => {
    let called = false
    let ret
  
    return () => {
        if (!called) {
            called = true
            ret = fn()
        }

        return ret
    }
}


export default function (loadGmapApi, GmapApi) {
    return function promiseLazyCreator (options) {
      // Things to do once the API is loaded
      function onApiLoaded () {
        GmapApi.gmapApi = {}
        return window.google
      }
  
      if (options.load) { // If library should load the API
        return lazy(() => { // Load the
          // This will only be evaluated once
          if (typeof window === 'undefined') { // server side -- never resolve this promise
            return new Promise(() => {}).then(onApiLoaded)
          } else {
            return new Promise((resolve, reject) => {
              try {
                window.vueGoogleMapsInit = resolve
                loadGmapApi(options.load)
              } catch (err) {
                reject(err)
              }
            }).then(onApiLoaded)
          }
        })
      } else { // If library should not handle API, provide
        // end-users with the global `vueGoogleMapsInit: () => undefined`
        // when the Google Maps API has been loaded
        const promise = new Promise(resolve => {
          if (typeof window === 'undefined') {
            // Do nothing if run from server-side
            return
          }
          window.vueGoogleMapsInit = resolve
        }).then(onApiLoaded)
  
        return lazy(() => promise)
      }
    }
  }