import ability from './ability'

export const canNavigate = to => {
    if (to.meta.resource == "Auth") {
        return true;
    }

    return to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
}

export const _ = undefined
