<template>
    <fieldset class="form-group mb-5">
        <legend :class="'col-form-label pt-0 pb-3 font-weight-bold ' + labelClass">{{label}}</legend>
        <slot></slot>
    </fieldset>
</template>

<script>
    export default {
        components: {},
        data: () => ({
        }),
        props: {
            label: String,
            labelClass: {
                type: String,
                default: ""
            }
        },
        computed: {

        },
        created() {

        },
        mounted() { },
        methods: {

        },
    }
</script>