const subjects = [
    { label: "DemoModul", value: "DemoModul" },
]

const fields = {
    "DemoModul": [

    ]
}

const routes = [

]

const navigation = [

]

export default {
    routes,
    subjects,
    fields,
    navigation
}