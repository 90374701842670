<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo ">
                <b-img :src="config.app.appLogoImage" alt="logo" />
                <h2 class="brand-text text-primary ml-10 mb-0">
                    {{config.app.appName}}
                </h2>
            </b-link>
            <!-- /Brand logo-->
            <!-- Left Text-->
            <b-col lg="8"
                   class="d-none d-lg-flex align-items-center p-5">
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-20">
                    <b-img fluid :src="imgUrl" />
                </div>
            </b-col>
            <!-- /Left Text-->
            <!-- Login-->
            <b-col lg="4"
                   class="d-flex align-items-center auth-bg px-20 p-lg-5">
                <b-col sm="8"
                       md="6"
                       lg="12"
                       class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2"
                                  class="font-weight-bold mb-10">
                        Velkommen til {{config.app.appName}}! 👋
                    </b-card-title>
                    <b-card-text class="mb-20">
                        
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form class="auth-login-form mt-20"
                                @submit.prevent>
                            <!-- email -->
                            <b-form-group label="E-mail"
                                          label-for="login-email">
                                <validation-provider #default="{ errors }"
                                                     name="E-mail"
                                                     rules="required|email">
                                    <b-form-input id="login-email"
                                                  v-model="userEmail"
                                                  :state="errors.length > 0 ? false:null"
                                                  name="login-email"
                                                  placeholder="john@example.com" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group class="mb-20">
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">Adgangskode</label>
                                    <!--<b-link :to="{name:'auth-forgot-password-v2'}">
                                        <small>Forgot Password?</small>
                                    </b-link>-->
                                </div>
                                <validation-provider #default="{ errors }"
                                                     name="Adgangskode"
                                                     rules="required">
                                    <b-input-group class="input-group-merge"
                                                   :class="errors.length > 0 ? 'is-invalid':null">
                                        <b-form-input id="login-password"
                                                      v-model="password"
                                                      :state="errors.length > 0 ? false:null"
                                                      class="form-control-merge"
                                                      :type="passwordFieldType"
                                                      name="login-password"
                                                      placeholder="············" />
                                        <b-input-group-append is-text>
                                            <feather-icon class="cursor-pointer"
                                                          :icon="passwordToggleIcon"
                                                          @click="togglePasswordVisibility" />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <!--<b-form-group>
                                <b-form-checkbox id="remember-me"
                                                 v-model="status"
                                                 name="checkbox-1">
                                    Remember Me
                                </b-form-checkbox>
                            </b-form-group>-->

                            <!-- submit buttons -->
                            <b-button type="submit"
                                      variant="primary"
                                      block
                                      @click="validationForm">
                                Log ind
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <!--<b-card-text class="text-center mt-20">
                        <span>New on our platform? </span>
                        <b-link :to="{name:'page-auth-register-v2'}">
                            <span>&nbsp;Create an account</span>
                        </b-link>
                    </b-card-text>-->


                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import { $themeConfig } from '@themeConfig'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import store from '@/store/index'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                config: $themeConfig,
                status: '',
                password: '',
                userEmail: '',
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                // validation rulesimport store from '@/store/index'
                required,
                email,
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            },
        },
        methods: {
            validationForm() {
                this.$refs.loginValidation.validate().then(success => {
                    if (success) {

                        this.$http.post("/api/login", {
                            email: this.userEmail,
                            password: this.password
                        }).then(x => {
                            if (!x.data.error) {
                                this.$store.dispatch("LOGIN", x.data)
                                this.$ability.update(x.data.acl)

                                this.$router.push("/")
                            } else {
                                this.$toaster({
                                    title: x.data.error,
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                })
                            }
                        })


                    }
                })
            },
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
