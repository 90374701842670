import loadGmapApi from './init'
import promiseLazyFactory from './lazy'

import Map from './map.vue'

let GmapApi = null

export function install (Vue, options) {

    GmapApi = new Vue({ data: { gmapApi: null } })

    const promiseLazyCreator = promiseLazyFactory(loadGmapApi, GmapApi)
    const gmapApiPromiseLazy = promiseLazyCreator(options)

    Vue.mixin({
        created () {
            //this.$gmapDefaultResizeBus = defaultResizeBus
            this.$gmapOptions = options
            this.$gmapApiPromise = gmapApiPromiseLazy
        }
    })

    Vue.prototype.$gmapApiPromise = gmapApiPromiseLazy

    Vue.component('GmapMap', Map)
}

export function gmapApi () {
    return GmapApi.gmapApi && window.google
}