<template>
    <div>
        <input class="form-control w-auto mb-5" type="text" v-model="city" @change="search" placeholder="Søg efter by" />

        <div class="vue-map-container w-100" :style="'min-height: ' + height + 'px'">
            <div ref="vue-map" class="vue-map"></div>
            <div class="vue-map-hidden">
                <slot></slot>
            </div>
            <slot name="visible"></slot>
        </div>
    </div>
</template>

<script>
const recyclePrefix = '__gmc__'

export default {
	components: {},
	data: () => ({
        $mapObject: null,
        shape: null,
        city: null
    }),
	props: {
        value: null,
        center: {
            required: true,
            type: Object,
        },
        zoom: {
            required: false,
            type: Number,
        },
        heading: {
            type: Number,
        },
        mapTypeId: {
            type: String
        },
        tilt: {
            type: Number
        },
        height: {
            type: String,
            default: "500"
        },
        options: {
            type: Object,
            default () { return {} }
        }
    },
	computed: {
        path: {
            get() {
                if (!this.value) {
                    return []
                }
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    watch: {
        zoom (zoom) {
            if (this.$mapObject) {
                this.$mapObject.setZoom(zoom)
            }
        }
    },
	created() {},
	mounted() {

        this.$gmapApiPromise().then(() => {
            // getting the DOM element where to create the map
            const element = this.$refs['vue-map']

            // creating the map
            var options = {
                zoom: this.zoom,
                center: this.center,
                mapTypeId: this.mapTypeId,
                ...this.options
            }

            const recycleKey = this.getRecycleKey()

            if (this.options.recycle && window[recycleKey]) {
                element.appendChild(window[recycleKey].div)
                this.$mapObject = window[recycleKey].map
                this.$mapObject.setOptions(options)
            } else {
                this.$mapObject = new google.maps.Map(element, options)
                window[recycleKey] = { map: this.$mapObject }
            }

            this.$mapObject.addListener('zoom_changed', () => {
                this.$emit('zoom_changed', this.$mapObject.getZoom())
            })

            this.$mapObject.addListener('bounds_changed', () => {
                this.$emit('bounds_changed', this.$mapObject.getBounds())
            })

            if (this.path.length > 0) {
                this.addPath(this.path)
            }

            return this.$mapObject
        })
        .catch(error => {

        })
    },
	methods: {
        getRecycleKey () {
            return this.options.recycle ? recyclePrefix + this.options.recycle : recyclePrefix
        },
        search() {
            var geocoder = new google.maps.Geocoder();

            geocoder.geocode({ address: this.city, region: "DK" }, (results, status) => {
                if (status == 'OK') {
                    this.$mapObject.setCenter(results[0].geometry.location);

                    var bounds = results[0].geometry.bounds
                    var northEast = bounds.getNorthEast()
                    var southWest = bounds.getSouthWest()

                    var path = [
                        { lat: northEast.lat(), lng: southWest.lng() },
                        { lat: northEast.lat(), lng: northEast.lng() },
                        { lat: southWest.lat(), lng: northEast.lng() },
                        { lat: southWest.lat(), lng: southWest.lng() },
                    ];

                    this.addPath(path)
                }
            });


        },
        addPath(obj) {
            if (this.shape) {
                this.shape.setMap(null);
            }

            if (obj != null && Array.isArray(obj)) {
                this.path = obj;
            } else {
                var bounds = this.$mapObject.getBounds()
                var northEast = bounds.getNorthEast()
                var southWest = bounds.getSouthWest()
                var center = bounds.getCenter()
                var degree = 1;
                var f = Math.pow(0.33, degree)

                var NW = { lat: northEast.lat(), lng: southWest.lng() };
                var NE = { lat: northEast.lat(), lng: northEast.lng() };
                var SE = { lat: southWest.lat(), lng: northEast.lng() };
                var SW = { lat: southWest.lat(), lng: southWest.lng() };

                obj = [
                    { lng: (1-f) * center.lng() + (f) * NW.lng, lat: (1-f) * center.lat() + (f) * NW.lat },
                    { lng: (1-f) * center.lng() + (f) * NE.lng, lat: (1-f) * center.lat() + (f) * NE.lat },
                    { lng: (1-f) * center.lng() + (f) * SE.lng, lat: (1-f) * center.lat() + (f) * SE.lat },
                    { lng: (1-f) * center.lng() + (f) * SW.lng, lat: (1-f) * center.lat() + (f) * SW.lat },
                ]

                this.path = obj;
            }

            this.shape = new google.maps.Polygon({
                paths: obj,
                strokeColor: "#0000FF",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#0000FF",
                fillOpacity: 0.35,
                draggable: true,
                editable: true,
                geodesic: false,
            });
            this.shape.setMap(this.$mapObject);


            var bounds = new google.maps.LatLngBounds();
            this.shape.getPaths().forEach(function(path) {
                path.forEach(function(latlng) {
                    bounds.extend(latlng);
                });
            });
            this.$mapObject.fitBounds(bounds, 10);


            this.shape.addListener("contextmenu", e => {
                if (e.vertex == undefined) {
                    this.shape.setMap(null);
                    this.path = [];
                    return;
                }
                var path = this.shape.getPath();
                path.removeAt(e.vertex);
            });

            this.shape.getPath().addListener("insert_at", e => {
                var path = [];
                this.shape.getPath().forEach(x => {
                    path.push(x.toJSON())
                });
                this.path = path;
            });

            this.shape.getPath().addListener("remove_at", e => {
                var path = [];
                this.shape.getPath().forEach(x => {
                    path.push(x.toJSON())
                });
                this.path = path;
            });

            this.shape.getPath().addListener('set_at', e => {
                var path = [];
                this.shape.getPath().forEach(x => {
                    path.push(x.toJSON())
                });
                this.path = path;
            });
        }
    },
    beforeDestroy () {
        const recycleKey = this.getRecycleKey()
        if (window[recycleKey]) {
            window[recycleKey].div = this.$mapObject.getDiv()
        }
    },
};
</script>


<style lang="css">
    .vue-map-container {
        position: relative;
    }
    .vue-map-container .vue-map {
        left: 0; right: 0; top: 0; bottom: 0;
        position: absolute;
    }
    .vue-map-hidden {
        display: none;
    }
</style>